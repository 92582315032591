import React, { useState, useRef, useEffect } from 'react';
import { FiEdit, FiPaperclip } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';

function Conversation({
  messages,
  sendMessage,
  editMessage,
  deleteMessage,
  newMessage,
  setNewMessage,
  messagesEndRef,
  targetClinicId,
  targetClinicName,
  selectedPatient,
  uploadFile
}) {
  const [editingMessage, setEditingMessage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    console.log("Messages in Conversation:", messages);
    console.log("Selected Patient:", selectedPatient);
  }, [messages, selectedPatient]);

  const handleEdit = (message) => {
    setEditingMessage(message);
    setNewMessage(message.text);
  };

  const handleSave = () => {
    editMessage(editingMessage.id, newMessage);
    setEditingMessage(null);
    setNewMessage('');
  };

  const handleFileUpload = async (files) => {
    setIsUploading(true);
    for (let file of files) {
      try {
        const url = await uploadFile(file);
        await sendMessage(null, url, file.type.startsWith('image/') ? 'image' : 'file');
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
    setIsUploading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newMessage.trim() !== '') {
      sendMessage(e);
    }
  };

  if (!selectedPatient) {
    return <div>No patient selected</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex-btn gap-4 bg-white rounded-xl p-2 border border-gray-100">
        <h2 className="text-lg font-semibold">{selectedPatient.patientName}</h2>
      </div>
      <div className="overflow-y-scroll space-y-4 sm:px-4 sm:max-h-[650px] max-h-[300px]">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`w-full flex gap-4 ${message.senderId === targetClinicId ? 'flex-row-reverse' : ''}`}
          >
            <div className={`w-10 h-10 rounded-full ${message.senderId === targetClinicId ? 'bg-green-400' : 'bg-cyan-400'} flex items-center justify-center border border-dashed border-subMain`}>
              <span className="text-2xl font-bold text-white">
                {message.senderName.charAt(0)}
              </span>
            </div>
            <div className="space-y-1">
              {editingMessage?.id === message.id ? (
                <input
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="py-3 rounded px-4 text-xs border border-gray-300"
                />
              ) : (
                <div
                  className={`py-3 rounded px-4 ${message.senderId === targetClinicId
                    ? 'bg-subMain text-white'
                    : 'bg-white text-main border border-gray-100'
                    }`}
                >
                  {message.type === 'image' && (
                    <img
                      src={message.url}
                      alt="Uploaded"
                      className="max-w-full h-auto cursor-pointer"
                      onClick={() => window.open(message.url, '_blank')}
                    />
                  )}
                  {message.type === 'file' && (
                    <a
                      href={message.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      {message.fileName || 'View File'}
                    </a>
                  )}
                  {(message.type === 'text' || message.type === undefined) && (
                    <p className="max-w-68 text-wrap leading-5">{message.text}</p>
                  )}
                </div>
              )}
              <p className="text-textGray text-xs">
                {message.timestamp || '時間不可用'}
              </p>
            </div>
            {message.senderId === targetClinicId && (
              <div className="flex gap-2">
                {editingMessage?.id === message.id ? (
                  <button onClick={handleSave} className="text-green-500">保存</button>
                ) : (
                  <>
                    <button onClick={() => handleEdit(message)} className="text-blue-500"><FiEdit /></button>
                    <button onClick={() => deleteMessage(message.id)} className="text-red-500"><RiDeleteBin6Line /></button>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="grid grid-cols-12 gap-2 bg-white rounded p-4">
        <input
          className="xs:col-span-9 col-span-7 border text-xs border-border rounded py-4 px-2"
          placeholder="輸入訊息..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <div className="xs:col-span-3 col-span-5 flex-btn gap-2">
          <button
            type="button"
            onClick={() => fileInputRef.current.click()}
            className="w-10 h-10 rounded bg-gray-200 flex justify-center items-center transitions text-lg"
          >
            <FiPaperclip />
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={(e) => handleFileUpload(e.target.files)}
            style={{ display: 'none' }}
            multiple
          />
          <button
            type="submit"
            className="w-full h-10 rounded bg-main text-white md:flex hidden justify-center items-center transitions text-lg"
          >
            傳送
          </button>
        </div>
      </form>
      <button type="submit" className="w-full h-12 rounded bg-main text-white md:hidden flex justify-center items-center transitions text-xs">
        傳送
      </button>
      {isUploading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded">
            <p>正在上傳...</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Conversation;