import { getDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../components/Firebase';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useUserStore = create(
  persist(
    (set, get) => ({
      user: null,
      joinedClinics: [],
      targetClinicId: null,
      targetClinicName: null,
      loading: true,
      setUser: (user) => set({ user }),
      setJoinedClinics: (joinedClinics) => set({ joinedClinics }),
      setTargetClinic: (clinicId, clinicName) => set({ targetClinicId: clinicId, targetClinicName: clinicName }),
      setLoading: (loading) => set({ loading }),
      fetchUserData: async (userId) => {
        try {
          const userDoc = await getDoc(doc(db, 'Users', userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            set({ user: userData });
            if (userData.joinClinic && userData.joinClinic.length > 0) {
              const clinicQuery = query(collection(db, 'OwnedClinic'), where('__name__', 'in', userData.joinClinic));
              const querySnapshot = await getDocs(clinicQuery);
              const clinics = querySnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name
              }));
              set({ joinedClinics: clinics });
              
              // If there's no targetClinicId set, set it to the first clinic
              const { targetClinicId } = get();
              if (!targetClinicId && clinics.length > 0) {
                set({ targetClinicId: clinics[0].id, targetClinicName: clinics[0].name });
              } else if (targetClinicId) {
                // Ensure the targetClinicName is set correctly
                const selectedClinic = clinics.find(clinic => clinic.id === targetClinicId);
                if (selectedClinic) {
                  set({ targetClinicName: selectedClinic.name });
                }
              }
            }
          }
          set({ loading: false });
        } catch (error) {
          console.error('Error fetching user data:', error);
          set({ loading: false });
        }
      },
    }),
    {
      name: 'user-storage', // name of the item in the storage (must be unique)
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useUserStore;