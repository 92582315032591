import React, { useEffect } from 'react';
import { Select, MenuSelect } from '../components/Form';
import { TbUser } from 'react-icons/tb';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { BiMenu, BiChevronDown } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import MenuDrawer from '../components/Drawer/MenuDrawer';
import { getAuth, signOut } from 'firebase/auth';
import { auth } from '../components/Firebase';
import useUserStore from '../stores/useUserStore';

function Header() {
  const [isOpen, setIsOpen] = React.useState(false);
  const { 
    user, 
    joinedClinics, 
    targetClinicId, 
    targetClinicName, 
    loading, 
    fetchUserData, 
    setTargetClinic 
  } = useUserStore();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData(user.uid);
      } else {
        useUserStore.getState().setUser(null);
        useUserStore.getState().setJoinedClinics([]);
        useUserStore.getState().setTargetClinic(null, null);
        useUserStore.getState().setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [fetchUserData]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const navigate = useNavigate();
  const DropDown1 = [
    {
      title: '設定',
      icon: TbUser,
      onClick: () => {
        navigate('/settings');
      },
    },
    {
      title: '登出',
      icon: AiOutlinePoweroff,
      onClick: handleLogout,
    }
  ];

  const getAvatar = () => {
    if (user?.photoURL) {
      return <img src={user.photoURL} alt="user" className="w-12 border border-border object-cover h-12 rounded-full" />;
    } else if (user?.email) {
      const firstChar = user.email.charAt(0).toUpperCase();
      return (
        <div className="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center text-xl font-bold text-gray-700">
          {firstChar}
        </div>
      );
    } else {
      return (
        <div className="w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center text-xl font-bold text-gray-700">
          ?
        </div>
      );
    }
  };

  const getDisplayName = () => {
    return user?.displayName ? user.displayName : user?.email ? user.email : '訪客';
  };

  const renderUserActions = () => {
    if (user) {
      return (
        <div className="items-center md:flex hidden">
          <MenuSelect datas={DropDown1}>
            <div className="flex gap-4 items-center p-4 rounded-lg">
              {getAvatar()}
              <p className="text-sm text-textGray font-medium">{getDisplayName()}</p>
            </div>
          </MenuSelect>
        </div>
      );
    } else {
      return (
        <div className="items-center md:flex hidden">
          <Link 
            to="/login" 
            className="relative text-xl text-blue-500 font-semibold px-4 py-2 rounded-md bg-primary hover:bg-primaryDark transition-colors animate-flash"
          >
            免費註冊登入
            <span className="absolute bottom-0 left-0 w-full h-1 bg-blue-300 animate-flash"></span> 
          </Link>
        </div>
      );
    }
  };

  const handleClinicChange = (clinic) => {
    setTargetClinic(clinic.id, clinic.name);
  };

  return (
    <>
      {isOpen && <MenuDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} />}

      <div className="xl:w-5/6 w-full 2xl:max-w-[1640px] bg-dry grid md:grid-cols-3 grid-cols-12 items-center bg-opacity-95 fixed top-0 z-40 xs:px-8 px-2">
        {/* Left side: Clinic Selection Dropdown */}
        <div className="md:col-span-1 sm:col-span-11 col-span-10 flex gap-4 items-center md:py-0 py-4">
          <button
            onClick={toggleDrawer}
            className="block xl:hidden border text-2xl bg-greyed w-16 md:w-12 h-12 rounded-md flex-colo text-textGray transitions hover:bg-border"
          >
            <BiMenu />
          </button>
          {user && joinedClinics.length > 0 && (
            <Select
              selectedPerson={{ id: targetClinicId, name: targetClinicName }}
              setSelectedPerson={handleClinicChange}
              datas={joinedClinics}
            >
              <div className="w-full flex items-center justify-between text-sm p-3 border border-border font-light rounded-lg focus:border focus:border-subMain">
                <span>{targetClinicName || '請先註冊診所或加入診所'}</span>
                <BiChevronDown className="text-xl" />
              </div>
            </Select>
          )}
        </div>
        
        {/* Center: Clinic Name */}
        <div className="md:col-span-1 hidden md:flex justify-center items-center">
          <h1 className="text-2xl font-semibold">{targetClinicName || ''}</h1>
        </div>

        {/* Right side: User Actions */}
        <div className="md:col-span-1 sm:col-span-1 col-span-2 items-center justify-end pr-4 md:pr-0">
          <div className="float-right flex gap-4 items-center justify-center">
            {renderUserActions()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;