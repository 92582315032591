// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDqvtW56VKz0wit4_jz-UD-YSW9sYDoiDA",
  authDomain: "medicalwindow-c8c4c.firebaseapp.com",
  databaseURL: "https://medicalwindow-c8c4c-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "medicalwindow-c8c4c",
  storageBucket: "medicalwindow-c8c4c.appspot.com",
  messagingSenderId: "866821958741",
  appId: "1:866821958741:web:3e80dac4e3feb42ff538dc",
  measurementId: "G-Y130GRN8QR"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});
export const auth = getAuth();
auth.useDeviceLanguage();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const db = getFirestore(app);
export const storage = getStorage(app);

// console.log("Firebase app initialized:", app);
// console.log("Firestore initialized:", db);
// console.log("Storage initialized:", storage);
// console.log("Auth initialized:", auth);